<template>
  <div class="choose-words-by-pic-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
        v-if="hasMenu"
      />
      <div class="title-area" v-if="lessonNum === 11">
        <div class="title-box">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ gameInfo.titlePinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ gameInfo.titleHanzi }}
            </div>
          </div>
        </div>
      </div>
      <!-- page  chooose hanzi -->
      <div class="choose-hanzi-box" :class="'lesson-' + lessonNum">
        <div class="answer-area">
          <div class="answer-img" :class="'lesson-' + lessonNum">
            <img :src="chooseHanziList[currentIndex].bgImg" alt="" />
          </div>
          <div
            class="answer-hanzi-area"
            v-if="!isShowAllWords"
            :class="'lesson-' + lessonNum"
          >
            <div class="hanzi-item hasInserted" v-if="isNeedTips">
              <span class="pinyin font-pinyin-medium">wǒ</span>
              <span class="font-hanzi-medium">我</span>
            </div>
            <div
              class="hanzi-item"
              v-for="(item, index) in chooseHanziList[currentIndex]
                .hanziRightList"
              :key="index + 'right'"
              :class="{
                active: insertIndex === index,
                hasInserted: rightHanziIndexArr.indexOf(index + 1) > -1,
              }"
            >
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>

          <div class="answer-right-hanzi-area" v-else>
            <div class="right-hanzi-box">
              <span class="pinyin font-pinyin-medium">{{
                chooseHanziList[currentIndex].wordsPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                chooseHanziList[currentIndex].wordsHanzi
              }}</span>
            </div>
          </div>
        </div>
        <!-- 有提示部分 -->
        <div class="choose-hanzi-area" v-if="isNeedTips">
          <div
            class="hanzi-item"
            v-for="(item, index) in optionsList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>

        <!-- 无提示部分 -->

        <div class="choose-hanzi-area" v-if="!isNeedTips">
          <div
            class="hanzi-item"
            v-for="(item, index) in chooseHanziList[currentIndex]
              .hanziChooseList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  name: "ChooseWordsByPicGame",
  props: {
    chooseHanziList: {
      type: Array,
      require: true,
    },
    optionsList: {
      type: Array,
      require: true,
    },
    buttonList: {
      type: Array,
      require: true,
    },
    starNum: {
      type: Number,
      require: true,
    },
    isNeedTips: {
      type: Boolean,
      default: false,
    },
    lessonNum: {
      type: Number,
      require: false,
    },
    gameInfo: {
      type: Object,
      require: false,
    },
    hasMenu: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    SceneSwitchThumbnails,
    PageButton,
  },

  data() {
    return {
      isShowAllWords: false,
      rightHanziIndexArr: [],
      lockIndex: 0,
      choosedHanziArr: [],
      insertIndex: 0,
      hanziSocketInfo: {},
      clickMenuSocketInfo: {},
      currentIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on("clickToChooseWords", ({ id, index }) => {
      this.handleChooseHanzi(id, index, true);
    });
    this.$bus.$on("chooseWordsByPicGameClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickToChooseWords");
    this.$bus.$off("chooseWordsByPicGameClickMenu");
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20043,
          data: { value },
          text: "ChooseWordsByPicGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004301,
          data: { value },
          text: "ChooseWordsByPicGame点击 menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.initGame();
    },
    initGame() {
      this.rightHanziIndexArr = [];
      this.lockIndex = 0;
      this.choosedHanziArr = [];
      this.insertIndex = 0;
      this.isShowAllWords = false;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleChooseHanzi(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      if (
        id ===
        this.chooseHanziList[this.currentIndex].hanziRightList[this.lockIndex]
          .id
      ) {
        this.choosedHanziArr.push(index);
        setTimeout(() => {
          this.lockIndex++;
          this.insertIndex++;
          this.rightHanziIndexArr.push(this.lockIndex);
          this.checkToNext();
        }, 10);
      } else {
        playCorrectSound(false);
      }
    },
    checkToNext() {
      if (
        this.lockIndex >=
        this.chooseHanziList[this.currentIndex].hanziRightList.length
      ) {
        this.isShowAllWords = true;
        playCorrectSound(true, false);
        this.nextPart();
      }
    },
    nextPart() {
      if (this.currentIndex >= this.chooseHanziList.length - 1) {
        startConfetti();
        playCorrectSound();
        this.isLastStep = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-words-by-pic-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;
    display: flex;
    flex-direction: column;
  }
  .title-area {
    width: 100%;
    .title-box {
      margin-top: 5%;
      padding: 10px;
      width: 45%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      width: fit-content;
      padding: 10px 25px;
      .title {
        // margin-left: 5%;
        .title-pinyin {
          // margin-left: 10px;
        }
      }
    }
  }
  .choose-hanzi-box {
    width: 100%;
    height: 100%;
    .answer-area {
      height: 70%;
      display: flex;
      align-items: center;
      .answer-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;
        img {
          width: 70%;
          //   border: 10px solid #fff;
          //   box-sizing: border-box;
          transform: rotate(-10deg);
        }
      }
      .lesson-11 {
        flex: 1.5;
      }
      .answer-hanzi-area {
        flex: 3;
        height: 40%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .hanzi-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 20px;
          border: 5px dashed #cfc8b0;
          border-radius: 20px;
          color: #f7f4e0;
          background: transparent;
          color: #fff;
          display: flex;
          flex-direction: column;
          width: 120px;
          height: 130px;
          padding: 20px 20px;
          justify-content: center;
          span {
            visibility: hidden;
            display: inline-block;
          }
        }
        .lesson-11 {
          height: 45%;
        }
        .active {
          border: 5px dashed #ce4c3f;
        }
        .hasInserted {
          border: none;
          background: #224e96;
          color: #fff;
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
          span {
            visibility: visible;
          }
        }
      }
      .lesson-11 {
        .hanzi-item {
          height: 45%;
        }
      }
      .answer-right-hanzi-area {
        height: 100%;
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-hanzi-box {
          border-radius: 30px;
          background: #224e96;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 130px;
          padding: 20px 70px;
          span {
            display: inline-block;
            color: #fff;
          }
        }
      }
    }
    .choose-hanzi-area {
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        box-sizing: border-box;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        background-color: #ce524c;
        color: #fff;

        border-bottom: 12px solid #a53d32;
        border-top: 12px solid #d87065;

        border-radius: 30px;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
      .dispear {
        opacity: 0;
      }
      .hanzi-item:hover {
        background-color: #912016;
        border-bottom: 12px solid #d87065;
        border-top: 12px solid #a53d32;
      }
    }
  }
  .lesson-11 {
    .choose-hanzi-area {
      height: 35%;
      align-items: flex-start;
    }
  }
}
</style>
