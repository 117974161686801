var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "choose-words-by-pic-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _vm.hasMenu
            ? _c("SceneSwitchThumbnails", {
                attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
                on: { changeTheNumbers: _vm.changeTheNumbers },
              })
            : _vm._e(),
          _vm.lessonNum === 11
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title-box" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " ")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "choose-hanzi-box",
              class: "lesson-" + _vm.lessonNum,
            },
            [
              _c("div", { staticClass: "answer-area" }, [
                _c(
                  "div",
                  {
                    staticClass: "answer-img",
                    class: "lesson-" + _vm.lessonNum,
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.chooseHanziList[_vm.currentIndex].bgImg,
                        alt: "",
                      },
                    }),
                  ]
                ),
                !_vm.isShowAllWords
                  ? _c(
                      "div",
                      {
                        staticClass: "answer-hanzi-area",
                        class: "lesson-" + _vm.lessonNum,
                      },
                      [
                        _vm.isNeedTips
                          ? _c(
                              "div",
                              { staticClass: "hanzi-item hasInserted" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "pinyin font-pinyin-medium" },
                                  [_vm._v("wǒ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-hanzi-medium" },
                                  [_vm._v("我")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.chooseHanziList[_vm.currentIndex].hanziRightList,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index + "right",
                                staticClass: "hanzi-item",
                                class: {
                                  active: _vm.insertIndex === index,
                                  hasInserted:
                                    _vm.rightHanziIndexArr.indexOf(index + 1) >
                                    -1,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "pinyin font-pinyin-medium" },
                                  [_vm._v(_vm._s(item.pinyin))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-hanzi-medium" },
                                  [_vm._v(_vm._s(item.hanzi))]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "answer-right-hanzi-area" }, [
                      _c("div", { staticClass: "right-hanzi-box" }, [
                        _c(
                          "span",
                          { staticClass: "pinyin font-pinyin-medium" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.chooseHanziList[_vm.currentIndex]
                                  .wordsPinyin
                              )
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "font-hanzi-medium" }, [
                          _vm._v(
                            _vm._s(
                              _vm.chooseHanziList[_vm.currentIndex].wordsHanzi
                            )
                          ),
                        ]),
                      ]),
                    ]),
              ]),
              _vm.isNeedTips
                ? _c(
                    "div",
                    { staticClass: "choose-hanzi-area" },
                    _vm._l(_vm.optionsList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index + "choose",
                          staticClass: "hanzi-item",
                          class: {
                            dispear: _vm.choosedHanziArr.indexOf(index) > -1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleChooseHanzi(item.id, index)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [_vm._v(_vm._s(item.pinyin))]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(_vm._s(item.hanzi)),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.isNeedTips
                ? _c(
                    "div",
                    { staticClass: "choose-hanzi-area" },
                    _vm._l(
                      _vm.chooseHanziList[_vm.currentIndex].hanziChooseList,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index + "choose",
                            staticClass: "hanzi-item",
                            class: {
                              dispear: _vm.choosedHanziArr.indexOf(index) > -1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleChooseHanzi(item.id, index)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [_vm._v(_vm._s(item.pinyin))]
                            ),
                            _c("span", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.hanzi)),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }